import * as Msdyn365 from '@msdyn365-commerce/core';
import { createObservableDataAction, IAction, IActionContext, ICreateActionContext } from '@msdyn365-commerce/core';
import { DataServiceQuery, IQueryResultSettings } from '@msdyn365-commerce/retail-proxy';
import { getLanguageNameAsync, languageNameQuery } from './DataActionExtension.g';
import { ILanguageNameEntity } from './DataServiceEntities.g';

/**
 * GetLanguageInput Input Action
 */
export class GetLanguageInput implements Msdyn365.IActionInput {
    constructor(ctx: Msdyn365.IRequestContext) {
        this.query = languageNameQuery().resultSettings({ Paging: { Top: 250 } });
        this.retailchannelRecid = ctx.channel?.RecordId;
        this.queryResultSettings = { Paging: { Top: 0 } };
    }

    public getCacheKey = () => `Language`;
    public getCacheObjectType = () => 'Language';
    public dataCacheType = (): Msdyn365.CacheType => 'request';

    public query: DataServiceQuery;
    public retailchannelRecid: number | undefined;
    public queryResultSettings: IQueryResultSettings;
}

async function action(input: GetLanguageInput, ctx: IActionContext): Promise<ILanguageNameEntity[]> {
    return getLanguageNameAsync({ callerContext: ctx }, input.retailchannelRecid || 0);
}

export default createObservableDataAction({
    action: <IAction<ILanguageNameEntity[]>>action,
    input: (context: ICreateActionContext) => {
        return new GetLanguageInput(context.requestContext);
    }
});
