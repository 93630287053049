import * as Msdyn365 from '@msdyn365-commerce/core';
import { createObservableDataAction, IAction, IActionContext, ICreateActionContext } from '@msdyn365-commerce/core';
import { DataServiceQuery, IQueryResultSettings } from '@msdyn365-commerce/retail-proxy';
import { churchRetailChannelCountryAsyncAsync, channelCountryQuery } from './DataActionExtension.g';
import { IChurchRetailChannelCountryEntity } from './DataServiceEntities.g';

/**
 * GetCountryLanguageInput Input Action
 */
export class GetChurchRetailChannelCountryInput implements Msdyn365.IActionInput {
    constructor(ctx: Msdyn365.IRequestContext) {
        this.query = channelCountryQuery().resultSettings({ Paging: { Top: 250 } });
        this.retailchannelRecid = ctx.channel?.RecordId;
        this.queryResultSettings = { Paging: { Top: 0 } };
    }

    public getCacheKey = () => `ChannelCountry`;
    public getCacheObjectType = () => 'ChannelCountry';
    public dataCacheType = (): Msdyn365.CacheType => 'request';

    public query: DataServiceQuery;
    public retailchannelRecid: number | undefined;
    public queryResultSettings: IQueryResultSettings;
}

async function action(input: GetChurchRetailChannelCountryInput, ctx: IActionContext): Promise<IChurchRetailChannelCountryEntity[]> {
    return churchRetailChannelCountryAsyncAsync({ callerContext: ctx }, input.retailchannelRecid || 0);
}

export default createObservableDataAction({
    action: <IAction<IChurchRetailChannelCountryEntity[]>>action,
    input: (context: ICreateActionContext) => {
        return new GetChurchRetailChannelCountryInput(context.requestContext);
    }
});
