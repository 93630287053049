import * as Msdyn365 from '@msdyn365-commerce/core';
import { createObservableDataAction, IAction, IActionContext, ICreateActionContext } from '@msdyn365-commerce/core';
import { DataServiceQuery, IQueryResultSettings } from '@msdyn365-commerce/retail-proxy';
import { countryLanguageQuery, getCountryLanguageAsync } from './DataActionExtension.g';
import { ICountryLanguageEntity } from './DataServiceEntities.g';

/**
 * GetCountryLanguageInput Input Action
 */
export class GetCountryLanguageInput implements Msdyn365.IActionInput {
    constructor(ctx: Msdyn365.IRequestContext) {
        this.query = countryLanguageQuery().resultSettings({ Paging: { Top: 250 } });
        this.retailchannelRecid = ctx.channel?.RecordId;
        this.queryResultSettings = { Paging: { Top: 0 } };
    }

    public getCacheKey = () => `CountryLanguage`;
    public getCacheObjectType = () => 'CountryLanguage';
    public dataCacheType = (): Msdyn365.CacheType => 'request';
    //cacheType application

    public query: DataServiceQuery;
    public retailchannelRecid: number | undefined;
    public queryResultSettings: IQueryResultSettings;
}

async function action(input: GetCountryLanguageInput, ctx: IActionContext): Promise<ICountryLanguageEntity[]> {
    return getCountryLanguageAsync({ callerContext: ctx }, input.retailchannelRecid || 0, 'en-us');
}

export default createObservableDataAction({
    action: <IAction<ICountryLanguageEntity[]>>action,
    input: (context: ICreateActionContext) => {
        return new GetCountryLanguageInput(context.requestContext);
    }
});
