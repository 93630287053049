import * as Msdyn365 from '@msdyn365-commerce/core';
import { createObservableDataAction, IAction, IActionContext, ICreateActionContext } from '@msdyn365-commerce/core';
import { DataServiceQuery, IQueryResultSettings } from '@msdyn365-commerce/retail-proxy';
import { lDSPolicyQuery, getChannelPoliciesAsync } from './DataActionExtension.g';
import { IChurchRetailChannelPolicyEntity } from './DataServiceEntities.g';

/**
 * GetPolicyInput Input Action
 */
export class GetPolicyInput implements Msdyn365.IActionInput {
    constructor(ctx: Msdyn365.IRequestContext) {
        this.query = lDSPolicyQuery().resultSettings({ Paging: { Top: 250 } });
        this.retailchannelRecid = ctx.channel?.RecordId;
        this.queryResultSettings = { Paging: { Top: 0 } };
    }

    public getCacheKey = () => `Policy`;
    public getCacheObjectType = () => 'Policy';
    public dataCacheType = (): Msdyn365.CacheType => 'request';

    public query: DataServiceQuery;
    public retailchannelRecid: number | undefined;
    public queryResultSettings: IQueryResultSettings;
}

async function action(input: GetPolicyInput, ctx: IActionContext): Promise<IChurchRetailChannelPolicyEntity[]> {
    return getChannelPoliciesAsync({ callerContext: ctx }, input.retailchannelRecid || 1);
}

export default createObservableDataAction({
    action: <IAction<IChurchRetailChannelPolicyEntity[]>>action,
    input: (context: ICreateActionContext) => {
        return new GetPolicyInput(context.requestContext);
    }
});
