const pageTitles = [
    { language: 'en-us', value: 'Store' },
    { language: 'es', value: 'Almacenar' },
    { language: 'fr', value: 'Conserver' },
    { language: 'it', value: 'Archivia' },
    { language: 'de', value: 'Online Store' },
    { language: 'pt-br', value: 'Loja' },
    { language: 'zh', value: '商店' },
    { language: 'ru', value: 'Хранить' },
    { language: 'ja', value: 'ストア' },
    { language: 'ko', value: '상점' },
    { language: 'fi', value: 'Myymälä' },
    { language: 'hu', value: 'Áruház' },
    { language: 'vt', value: 'Cửa Hàng' }
];

export default pageTitles;
