import { createObservableDataAction, IAction, ICreateActionContext, IActionContext } from '@msdyn365-commerce/core';
import * as Msdyn365 from '@msdyn365-commerce/core';
import { Cart } from '@msdyn365-commerce/retail-proxy';
import { getCartState } from '@msdyn365-commerce/global-state';

export class GetCartInput implements Msdyn365.IActionInput {
    public getCacheKey = () => `Cart`;
    public getCacheObjectType = () => 'Cart';
    public dataCacheType = (): Msdyn365.CacheType => 'request';
}

async function action(_input: any, ctx: IActionContext): Promise<Readonly<Cart>> {
    const cartState = await getCartState(ctx);
    const cart = cartState.cart;

    return cart;
}

export default createObservableDataAction({
    action: <IAction<Readonly<Cart>>>action,
    input: (_context: ICreateActionContext) => {
        return new GetCartInput();
    }
});
