import * as Msdyn365 from '@msdyn365-commerce/core';
import { createObservableDataAction, IAction, IActionContext, ICreateActionContext } from '@msdyn365-commerce/core';
import { DataServiceQuery, IQueryResultSettings } from '@msdyn365-commerce/retail-proxy';
import { getCustomerSubscriptionsAsync, subscriptionsQuery } from './DataActionExtension.g';
import { ICPBCustomerSubscriptionsEntity } from './DataServiceEntities.g';

/**
 * GetCountryLanguageInput Input Action
 */
export class GetCustomerSubscriptionsInput implements Msdyn365.IActionInput {
    constructor(ctx: Msdyn365.IRequestContext) {
        this.query = subscriptionsQuery().resultSettings({ Paging: { Top: 250 } });
        this.retailchannelRecid = ctx.channel?.RecordId;
        this.queryResultSettings = { Paging: { Top: 0 } };
    }

    public getCacheKey = () => `CustomerSubscriptions`;
    public getCacheObjectType = () => 'CustomerSubscriptions';
    public dataCacheType = (): Msdyn365.CacheType => 'request';

    public query: DataServiceQuery;
    public retailchannelRecid: number | undefined;
    public queryResultSettings: IQueryResultSettings;
}

async function action(input: GetCustomerSubscriptionsInput, ctx: IActionContext): Promise<ICPBCustomerSubscriptionsEntity[]> {
    return getCustomerSubscriptionsAsync(
        { callerContext: ctx },
        ctx.requestContext.user.customerAccountNumber || '',
        ctx.requestContext.geoLocation?.region || ''
    );
}

export default createObservableDataAction({
    action: <IAction<ICPBCustomerSubscriptionsEntity[]>>action,
    input: (context: ICreateActionContext) => {
        return new GetCustomerSubscriptionsInput(context.requestContext);
    }
});
