import { createObservableDataAction, IAction, ICreateActionContext, IActionContext } from '@msdyn365-commerce/core';
import { DataServiceQuery, IQueryResultSettings } from '@msdyn365-commerce/retail-proxy';
import * as Msdyn365 from '@msdyn365-commerce/core';
import { churchUnitBillingQuery, getUnitBillingAsync } from './DataActionExtension.g';
import { IChurchUnitBillingEntity } from './DataServiceEntities.g';
import { getCartState } from '@msdyn365-commerce/global-state';

/**
 * GetPaymentOptions Input Action
 */
export class GetPaymentOptionsInput implements Msdyn365.IActionInput {
    constructor(ctx: Msdyn365.IRequestContext) {
        this.query = churchUnitBillingQuery().resultSettings({ Paging: { Top: 0 } });
        this.accountNum = ctx.user.customerAccountNumber as string;
        this.retailchannelRecid = ctx.channel?.RecordId;
        this.queryResultSettings = { Paging: { Top: 0 } };
    }

    public getCacheKey = () => `PaymentOptions`;
    public getCacheObjectType = () => 'PaymentOptions';
    public dataCacheType = (): Msdyn365.CacheType => 'request';

    public query: DataServiceQuery;
    public accountNum: string;
    public retailchannelRecid: number | undefined;
    public queryResultSettings: IQueryResultSettings;
}

async function action(input: GetPaymentOptionsInput, ctx: IActionContext): Promise<IChurchUnitBillingEntity[]> {
    const cartState = await getCartState(ctx);
    const cart = cartState.cart;
    return getUnitBillingAsync({ callerContext: ctx }, input.accountNum, input.retailchannelRecid || 0, cart);
}

export default createObservableDataAction({
    action: <IAction<IChurchUnitBillingEntity[]>>action,
    input: (context: ICreateActionContext) => {
        return new GetPaymentOptionsInput(context.requestContext);
    }
});
